var fn={};
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$(function(){
	fn={
		Launch:function(){
			fn.owlCarousel();
			fn.swipebox();
            fn.matchHeight();
            fn.swipebox();
            fn.loadPriceRange();
            fn.masonry();
            fn.nicescroll();
			fn.App();
            fn.validation();
            fn.searchForm();
            fn.languageSelect();
            fn.bookingForm();
            fn.countrySelection();
            fn.slick();
		},
		App:function(){
            var $myCarousel = $('#carousel-example-generic');
            // Initialize carousel
            $myCarousel.carousel();

            function doAnimations(elems) {
              var animEndEv = 'webkitAnimationEnd animationend';
              
              elems.each(function () {
                var $this = $(this),
                    $animationType = $this.data('animation');

                // Add animate.css classes to
                // the elements to be animated 
                // Remove animate.css classes
                // once the animation event has ended
                $this.addClass($animationType).one(animEndEv, function () {
                  $this.removeClass($animationType);
                });
              });
            }

            // Select the elements to be animated
            // in the first slide on page load
            var $firstAnimatingElems = $myCarousel.find('.item:first')
                                       .find('[data-animation ^= "animated"]');

            // Apply the animation using our function
            doAnimations($firstAnimatingElems);

            // Pause the carousel 
            $myCarousel.carousel('pause');

            // Attach our doAnimations() function to the
            // carousel's slide.bs.carousel event 
            $myCarousel.on('slide.bs.carousel', function (e) { 
              // Select the elements to be animated inside the active slide 
              var $animatingElems = $(e.relatedTarget)
                                    .find("[data-animation ^= 'animated']");
              doAnimations($animatingElems);
            });


            $(".toggle-search").on("click",function(){
                $(".navbar .navbar-form").slideToggle();
            });

            // review-form-toggle
             $("#display-review-form").on("click",function(e){
                $("div[data-toggle='display-review-form']").slideToggle();
                $("html,body").animate({
                  scrollTop:$(".review-form").offset().top - 60
                },1000)
                e.preventDefault();

             });

             $(".form-close").on("click",function(){
                $("div[data-toggle='display-review-form']").slideToggle();
             });

             // scrollspy

             if($(".inner-package").length>0){
                 $(window).scroll(function(){
                    var wScroll=$(this).scrollTop();

                    if(wScroll > $(".inner-package").offset().top  && wScroll < $(".block-subscribe").offset().top - 60){

                        $(".scrollspy-menu").fadeIn();
                    }else{
                        $(".scrollspy-menu").fadeOut();
                    }
                 });
             }
             var related_package=10;
             if($(".related-package").length > 0){
                related_package=$('.related-package').outerHeight(true);
             }


                var lastId;
                var topMenu = $(".scrollspy-menu nav");
                var menuItems = topMenu.find("a");
                var scrollItems = menuItems.map(function(){
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
             });

             // Bind to scroll
              $(window).scroll(function(){
                 // Get container scroll position
                 var fromTop = $(this).scrollTop()+150;
                 
                 // Get id of current scroll item
                 var cur = scrollItems.map(function(){
                   if ($(this).offset().top < fromTop)
                     return this;
                 });
                 // Get the id of the current element
                 cur = cur[cur.length-1];
                 var id = cur && cur.length ? cur[0].id : "";
                 
                 if (lastId !== id) {
                     lastId = id;
                     // Set/remove active class
                     menuItems
                       .parent().removeClass("active")
                       .end().filter("[href='#"+id+"']").parent().addClass("active");
                 } 
              });

               menuItems.on("click",function(e){
                 var pos=$(this).attr("href");
                 console.log(pos);

                 $("html,body").animate({
                      scrollTop:($(".inner-package "+pos).offset().top) - 100
                 },1000);
                 e.preventDefault();
              });

            if($(window).width() > 991){

                if($(".fixed-menu").length >0){

                    $('.fixed-menu').affix({
                      offset: {
                        top:$(".fixed-menu").offset().top + 100,
                        bottom: function () {

                          return (this.bottom = ($('.package-detail__review').outerHeight(true) + related_package + $('.block-subscribe').outerHeight(true) + $("#bottom-footer").outerHeight(true) + 10))
                        }
                      }
                    });
                }
            }


            // scrollspy-menu

            $(".scrollspy-menu nav .checkbox input").change(function(){
                $(this).parent('.checkbox').next().slideToggle();
            });

            if($(window).width() < 881){
                menuItems.on("click",function(){
                    $(".scrollspy-menu nav .checkbox input").prop('checked', false);
                    $(".scrollspy-menu nav ul").slideUp();
                });
            }


            // score-breakdown

            $(".score-break .meter span").each(function(){
                $(this).css("width",$(this).data('meter'));
            });

            // view all itinerary
            $(".package-detail__itinerary #view-all").on("click",function(){
                var hide_text = $(this).data('hide');
                var show_text = $(this).data('show');
                if($(this).attr('data-status') == 0){
                    $(".package-detail__itinerary .itinerary-list").find(".description").slideDown();
                    $(this).text(hide_text);
                    $(this).attr('data-status',1);
                }else{
                    $(".package-detail__itinerary .itinerary-list").find(".description").slideUp();
                    $(this).text(show_text);
                    $(this).attr('data-status',0);
                }
                
            });

            // faq collapse

            $("ul.faqs-question li .question").on("click",function(){
                $(this).toggleClass("open").parent('li').find('.answer').slideToggle(100);
            });

            // package listing filter accordion
            $(document).on("click",".accordion-block .block .header",function(){
                $(this).closest(".block").toggleClass("collapse-up").find(".toggle").slideToggle();
            });

             // challenges accordion
            $(".challenges ul li .header").on("click",function(e){
                $(".challenges ul li .description").slideUp();
                
                $(this).parent("li").find(".description").slideDown();
            });

               // recently-viewed

            $(".block-recently-viewed .btn-recent").on("click",function(){
                $(".recently-viewed-container").toggleClass("open");
            });

            $(".recently-viewed-container ul").blur(function(){
                $(this).parent().removeClass("open");
            });
            $(".recently-viewed-container .btn-close").on("click",function(){
                $(this).closest(".recently-viewed-container").removeClass("open");
            });


            // navbar

            $(".navbar-nav .dropdown-menu .main-nav > li > a").on("mouseover",function(){
                $(".navbar-nav .dropdown-menu .main-nav > li").removeClass("open");
                $(this).parent("li").addClass("open");
            });


            

        },
        owlCarousel:function(){
            $(".block-adventure .block-wrapper .row").owlCarousel({
                items:3,
                nav:true,
                navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
                responsiveClass:true,
                    responsive:{
                        0:{
                            items:1,
                        
                        },
                        480:{
                            items:2,
                            
                        },
                        768:{
                           items:2
                        },
                        992:{
                            items:3
                        }
                    }
                });

            $(".block-activities .block-item__slider ").owlCarousel({
                items:4,
                nav:true,
                navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
                responsiveClass:true,
                    responsive:{
                        0:{
                            items:1,
                        
                        },
                        480:{
                            items:2
                        },
                        768:{
                           items:2
                        },

                        992:{
                            items:3
                        },
                        1024:{
                            items:4
                        }
                    }
                });

            if($(window).width() < 660){
                $(".block-featured .block-featured-slider-sm").owlCarousel({
                    
                    nav:true,
                    navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
                    responsiveClass:true,
                    responsive:{
                        0:{
                            items:1,
                        
                        },
                        480:{
                            items:2,
                            
                        },
                        659:{
                           items:2
                        }
                    }
                });
            }


           
             
           


            $(".related-package .content .item-grid").owlCarousel({
                items:4,
                loop:true,
                autoplay:true,
                autoplayTimeout:3000,
                autoplayHoverPause:true,
                nav:true,
                navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
                responsiveClass:true,
                    responsive:{
                        0:{
                            items:1,
                        
                        },

                        372:{
                            items:1,
                        },

                        400:{
                            items:2,
                            
                        },
                        768:{
                           items:3
                        },
                        991:{
                            items:3
                        },
                        992:{
                            items:4
                        }
                    }
            });

              function owlControlNav($wrapper,$items='.owl-item'){
                var viewport = $(window).width();
                if($($wrapper).length > 0){
                    var itemCount = $($wrapper+" "+$items).length;
                    console.log($wrapper+" "+$items+">>"+itemCount);
                    if(
                        (viewport >= 900 && itemCount <= 4) //desktop
                        || ((viewport >= 600 && viewport < 900) && itemCount <= 3) //desktopsmall
                        || ((viewport >= 479 && viewport < 600) && itemCount <= 3) //tablet
                        || (viewport < 479 && itemCount <=2) //mobile
                    )
                    {
                         $($wrapper+' .owl-controls .owl-nav').hide();
                    } 
                    else
                    {
                         $($wrapper+' .owl-controls .owl-nav').show();
                    }
                }
            }



         
            owlControlNav(".block-adventure .block-wrapper",'.owl-item');
            owlControlNav(".block-activities .block-wrapper",".item-wrap");

       

        },
        swipebox:function(){
            $( '.swipebox' ).swipebox();
             $( '.swipebox-video' ).swipebox();
        },
        matchHeight:function(){
            if($(window).width() < 480){
                $(".testimonial .block .nav-tabs > li > a").matchHeight({
                    byRow:false
                });
            }
            $(".form-card").matchHeight();
            $(".listing-packages-wrapper .image img").matchHeight();
            $(".listing-packages-wrapper .description h2").matchHeight();
            $(".listing-packages-wrapper .description p").matchHeight();
            $(".listing-packages-wrapper .description .text").matchHeight();
            $(".block-featured .description .text").matchHeight();
            $(".block-featured .description h2").matchHeight();
             $(".block-featured .description p").matchHeight();
            $(".destination-page .destination-package .package .description h2").matchHeight({
                byRow:false,
                property:'min-height'
            });
            $(".destination-page .destination-package .package .description p").matchHeight({
                byRow:false,
                property:'min-height'
            });
            $(".block-adventure .content .main-package-block .caption h2").matchHeight({
                byRow:false,
                property:'min-height'
            });

            $(".related-package .description h2").matchHeight();
             $(".related-package .description p").matchHeight();

            $(document).on('click', '.navbar-nav .dropdown-toggle', function (e) {
                $(".main-nav__sub--package .item h2").matchHeight({
                    property:'min-height',
                    byRow:'true'
                });
            });
            if($(".home-slider").length > 0){
                $(".home-slider .carousel .carousel-inner .item .carousel-caption").matchHeight({
                    byRow:false,
                    property: 'min-height'
                });
            }
        },
        masonry:function(){
            if($(window).width() > 639){
                var $container = $(".grid");

                $container.imagesLoaded(function () {
                    $container.masonry({
                         itemSelector: '.grid-item'
                    });
                });
               
            }
        },
        selectMember:function(){
            $('#select-member').on('change',function(){
                var id = $(this).val();
                if(id==""){
                    $('.emps').show(500);
                }else{
                    $('.emps').hide();
                    $('#emp_'+id).show(500);
                }
            }); 
        },
        languageSelect:function(){
            $('#lg-select').on('change',function(){
                var selected = $(this).val();
                console.log(selected);
                if(selected!='javascript:void(0);'){
                    window.location.href=selected;
                }
            })
        },
		findTrips: function(){
        	$('#find_activity').prop('disabled', true);
            $('#find_destination').change(function(e) {
                e.preventDefault();
                var val = $(this).val();
                if (val != '') {
                    $('#find_activity').html('<option>Loading...</option>');
                    $('#find_activity').prop('disabled', false);
                    fn.findActivity();
                } else {
                    $('#find_activity').prop('disabled', true);
                    return false;
                }
            });
            $('#budget-selection').on('change',function(){
            	var selected = $(this).val();
            	var min=$(this).data('min'),max=$(this).data('max');
            	if(selected == 'below'){
            		max = 500;
            	}else if(selected == 'above'){
            		min = 3000;
            	}else{
            		var arr = selected.split("-");
            		min = arr[0];
            		max = arr[1];
            	}
            	$('#min-price').val(min);
            	$('#max-price').val(max);
            });	
        },
        findActivity:function() {
            var url = $('#find_destination').data('action');
            var val = $('#find_destination').val();
            if (val != '') {
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: {
                        slug: val
                    },
                    success: function(response) {
                        var resData = response.activity;
                        var selData = $('#find_activity').data('select');
                        
                        $('#find_activity').html(resData);
                        if (response.status == 'failed') {
                            $('#find_activity').prop('disabled', true);
                        } else {
                            $('#find_activity').prop('disabled', false);
                        }

                        if(selData !=""){
                            $('#find_activity option[value='+selData+']').prop('selected',true);
                        }
                    }
                });
            }
        },
        loadBlogs:function(){
            var offset = 0,totalCount=$('#load-more-blog').data('total');

            $('#load-more-blog').on('click',function(){
                var _this = $(this);
                var load = $('#fountain-bars').attr('src');
                var limit = _this.data('limit');
                var condition = _this.data('condition');
                var loadUrl = _this.data('url');
                var ct = _this.data('content');
                offset = offset + limit;
                _this.attr('disabled','disabled');
                _this.html('<img src="'+load+'"/>');

                $.ajax({
                    url:loadUrl,
                    data:{limit:limit,offset:offset, condition:condition},
                    type:'post',
                    dataType:'json',
                    success:function(data){
                        if(data.status=='success'){
                            var html = data.html;
                            var el = jQuery(html);
                            $('.blog-listing .grid').append(el).masonry( 'appended', el, true );
                            if(limit+offset >= totalCount){
                                _this.hide();
                            }
                            //console.log('total='+totalCount+' limit&offset='+limit+'>>'+offset)
                        }
                    },
                    complete:function(){
                        _this.removeAttr('disabled');
                        _this.html(ct);
                    }
                })
            });
        },
        loadReviews:function(){
            var offset = 0,totalCount=$('#load-more-reviews').data('total');

            $('#load-more-reviews').on('click',function(){
                var _this = $(this);
                var load = $('#fountain-bars').attr('src');
                var limit = _this.data('limit'), loadUrl = _this.data('url'), pid = _this.data('pid')
                var ct = _this.data('content');
                offset = offset + limit;
                _this.attr('disabled','disabled');
                _this.html('<img src="'+load+'"/>');

                $.ajax({
                    url:loadUrl,
                    data:{limit:limit,offset:offset,pid:pid},
                    type:'post',
                    dataType:'json',
                    success:function(data){
                        if(data.status=='success'){
                            var html = data.html;
                            var el = jQuery(html);
                            $('#reviews-list').append(el);
                            $('.cur-rev-count').html(offset+data.count);
                            if(limit+offset >= totalCount){
                                _this.hide();
                            }
                            //console.log('total='+totalCount+' limit&offset='+limit+'>>'+offset)
                        }
                    },
                    complete:function(){
                        _this.removeAttr('disabled');
                        _this.html(ct);
                    }
                })
            });
        },

        loadTestimonials:function(){
            var offset = $('#load-more-testimonials').data('offset'),totalCount=$('#load-more-testimonials').data('total');
            console.log("offset:"+offset + " & total:"+totalCount)
            $('#load-more-testimonials').on('click',function(){
                var _this = $(this);
                var load = $('#fountain-bars').attr('src');
                var limit = _this.data('limit'), loadUrl = _this.data('url');
                var ct = _this.data('content');
                var ex = _this.data('exclude');

                console.log(" limit:"+limit);
                
                _this.attr('disabled','disabled');
                _this.html('<img src="'+load+'"/>');

                $.ajax({
                    url:loadUrl,
                    data:{limit:limit,offset:offset,exclude:ex},
                    type:'post',
                    dataType:'json',
                    success:function(data){
                        if(data.status=='success'){
                            var html = data.html;
                            var el = jQuery(html);
                            $('.testimonial-wrapper ul').append(el);
                            offset = offset + limit;
                            if(offset+limit > totalCount){
                                _this.hide();
                            }
                            console.log('total='+totalCount+' limit&offset='+limit+'>>'+offset)
                        }
                    },
                    complete:function(){
                        _this.removeAttr('disabled');
                        _this.html(ct);
                    }
                })
            });
        },
        loadCountries:function(container,url){
            var selected = "";
            if($(container).data('selected')!==undefined){
                selected = $(container).data('selected');
            }
            $.getJSON(url, function(data) {
                var items = '';
                $.each(data, function(key, val) {
                    items += "<option value='" + val.name + "' data-code='" + val.code + "' "+(selected==val.name?"selected='selected'":"")+">" + 
                                val.name + 
                            "</option>";
                });
                $(container).append(items);
            });
        },
        searchForm:function(){
            $('#keyword-search-form').submit(function(){
                var keyword = $(this).find('input[name=keyword]');
                if(keyword.val() == ""){
                    $(this).find('.error').html('Please enter keyword to search!');
                    $(this).find('.error').show();
                    return false;
                }
            });
            $('#keyword-search-form input[name=keyword]').on('keyup',function(){
                $('#keyword-search-form .error').hide();
            })
            $('#keyword-search-small-form').submit(function(){
                var keyword = $(this).find('input[name=keyword]');
                if(keyword.val() == ""){
                    $(this).find('.error').html('Please enter keyword to search!');
                    $(this).find('.error').show();
                    return false;
                }
            });
            $('#keyword-search-small-form input[name=keyword]').on('keyup',function(){
                $('#keyword-search-small-form .error').hide();
            })
        },
        createElevationChart:function(elevData){
            var chart = null;
            
            if(typeof Morris=='object' && typeof elevData=='object'){
                chart = Morris.Line({
                    element: 'elevation-chart',
                    data:elevData,
                    xkey:'day',
                    ykeys:['height'],
                    labels: ['Height (in metres)'],
                    parseTime: false,
                    pointSize: 2,
                    hideHover: 'auto',
                    resize: true,
                    opacity:0.5,
                    lineColors: ['#E59400', '#999966', '#cc6666', '#663333'],
                    gridLineColor: '#555',
                });
            }
            return chart;
        },
        submitEnquiry:function(form) {
            var _btn = $('#package-enquiry button[type=submit]');
            _btn.html('Sending...');
            _btn.attr('disabled','disabled');
            $.ajax({
                url: $(form).attr('action'),
                data: $(form).serialize(),
                type: 'POST',
                success: function(response) {
                    var icon = 'warning';
                    var type = 'danger';
                    if (response.status == 'success') {
                        icon = 'check';
                        type = 'success';
                        $(form)[0].reset();
                        var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
                        $('#package-enquiry').html(content);
                    }
                },
                complete:function(){
                    _btn.html('Send');
                    _btn.removeAttr('disabled');
                    fn.refreshCaptcha(); 
                },
                error :function( jqXhr ) {
                  
                    //process validation errors here.
                    $errors = jqXhr.responseJSON; //this will get the errors response data.
                    //show them somewhere in the markup
                    

                    $.each( $errors, function( key, value ) {
                        console.log(key+" "+value);
                        var _inp = $(form).find('input[name='+key+']');
                        console.log(_inp);
                        var _errelm = _inp.siblings('label.error');
                        console.log(_errelm);
                        if(_errelm.length == 0){
                            console.log(';here');
                            var div = document.createElement('label');
                            div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
                            div.innerHTML = value;
                            _inp.after(div);
                        }else{
                            console.log('there is an elm');
                            _errelm.html(value);
                            _errelm.show();
                        }
                    });
                }
            });
        },
        submitHomeStayEnquiry:function(form) {
            var _btn = $('#homestay-enquiry-form button[type=submit]');
            _btn.html('Sending...');
            _btn.attr('disabled','disabled');
            $.ajax({
                url: $(form).attr('action'),
                data: $(form).serialize(),
                type: 'POST',
                success: function(response) {
                    var icon = 'info-circle';
                    var type = 'danger';
                    if (response.status == 'success') {
                        icon = 'check-circle-o';
                        type = 'success';
                        $(form)[0].reset();
                        var content = '<div class="alert alert-' + type + ' alert-effect in text-center">' + '<i class="fa fa-' + icon + ' fa-2x"></i><br> ' + response.message + '</div>';
                        $('#homestay-enquiry-form').html(content);
                        $('html,body').animate({
                            scrollTop:$('#homestay-enquiry-form').offset().top-100
                        },800);
                    }
                },
                complete:function(){
                    _btn.html('Send');
                    _btn.removeAttr('disabled');
                    fn.refreshCaptcha(); 
                },
                error :function( jqXhr ) {
                    grecaptcha.reset();
                    //process validation errors here.
                    $errors = jqXhr.responseJSON; //this will get the errors response data.
                    //show them somewhere in the markup
                    

                    $.each( $errors, function( key, value ) {
                        console.log(key+" "+value);
                        if(key=='g-recaptcha-response'){
                            var _inp = $(form).find('#g-recaptcha-response');
                        }else{
                            var _inp = $(form).find('input[name='+key+']');
                        }
                        console.log(_inp);
                        var _errelm = _inp.siblings('label.error');
                        console.log(_errelm);
                        if(_errelm.length == 0){
                            console.log(';here');
                            var div = document.createElement('label');
                            div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
                            div.innerHTML = value;
                            _inp.after(div);
                        }else{
                            console.log('there is an elm');
                            _errelm.html(value);
                            _errelm.show();
                        }
                    });
                }
            });
        },
        saveCallRequest:function (form){
            var _btn = $('#call-request button[type=submit]');
            _btn.html('Sending...');
            _btn.attr('disabled','disabled');
            $.ajax({
                url: $(form).attr('action'),
                data: $(form).serialize(),
                type: 'POST',
                success: function(response) {
                    var icon = 'warning';
                    var type = 'danger';
                    if (response.status == 'success') {
                        icon = 'check';
                        type = 'success';
                        $(form)[0].reset();
                        var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
                        $('#call-request').html(content);
                    }
                },
                complete:function(){
                    _btn.html('Send');
                    _btn.removeAttr('disabled');
                    fn.refreshCaptcha(); 
                },
                error :function( jqXhr ) {
                    //process validation errors here.
                    $errors = jqXhr.responseJSON; //this will get the errors response data.
                    //show them somewhere in the markup
                    $.each( $errors, function( key, value ) {
                        var _inp = $(form).find('input[name='+key+']');
                        var _errelm = _inp.siblings('label.error');
                        if(_errelm.length == 0){
                            var div = document.createElement('label');
                            div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
                            div.innerHTML = value;
                            _inp.after(div);
                        }else{
                            _errelm.html(value);
                            _errelm.show();
                        }
                    });
                }
            });
        },
        countrySelection: function(){
            if($('#country_selection').length>0){
                fn.countrySelected('#country_selection','#state_selection');
                $('#country_selection').change(function(){
                    fn.countrySelected('#country_selection','#state_selection');
                });
            }
            if($('#review_country').length>0){
                fn.countrySelected('#review_country','#review_state');
                $('#review_country').change(function(){
                    fn.countrySelected('#review_country','#review_state');
                });
            }
        },
        countrySelected: function(elm,stateElm){
            var fb = $("#loaders").data('loader');
            var cid = $(elm).find('option:selected').data('id');
            var url = $(elm).data('url');
            var sel = $(stateElm).data('val');
            if(sel == undefined || !sel){
                sel=null;
            }

            if(cid){
                $(stateElm).parent().append("<img src='"+fb+"' class='email-check-loader'/>");
                $.ajax({
                    url:url,
                    data:{cid:cid,sid:sel},
                    type:'post',
                    success:function(data){
                        $(stateElm).html($(data).html());
                        $(stateElm).siblings(".email-check-loader").remove();
                    }
                });
            }
        },
        bookingForm: function(){
            var bookdateOpt = {
                format: "yyyy-mm-dd",
                weekStart: 0,
                autoclose:true,
                startDate:'today'
            };
            $('.date-select #start_date')
                .datepicker(bookdateOpt)
                .on('changeDate',function(selected){
                    startDate = selected.date.valueOf();
                    endDate = $('.date-select #end_date');
                    if(endDate!=null && (new Date(startDate).getTime() > new Date(endDate.val()).getTime())){
                        endDate.datepicker('setDate', new Date(startDate));
                    }
                    endDate.datepicker('setStartDate', new Date(startDate));
                });
            $('.date-select #end_date').datepicker(bookdateOpt);

            $('.date-select .trigger-cal').click(function(){
                $(this).siblings('input').focus();
            });

            //var json_url = $('#booking-form').data('jsonurl');
            //fn.loadCountries('#country_name',json_url);
        },
        refreshCaptcha:function(){
            var captcha = $('.captcha-img');
            var config = captcha.data('refresh-config');
            captcha.css('opacity','0.1');
            $.ajax({
                method: 'POST',
                url: captcha.attr('data-get')+"/" + config,
            }).done(function (response) {
                captcha.prop('src', response);
                captcha.css('opacity','1');
            });
        },
        saveReview:function(form) {
            var _btn = $(form).find('button.btn.btn-submit');
            console.log(_btn.length);
            var _loader = $('#white-uploader').attr('src');
            var txt = _btn.data('text');
            _btn.html('Submitting...');
            _btn.attr('disabled','disabled');
            $.ajax({
                data: $(form).serialize(),
                url: $(form).attr('action'),
                type: 'post',
                dataType:'JSON',
                success: function(response) {
                    if (response) {
                        //var response = $.parseJSON(data);
                        if (response.status == 'success') {
                            if(response.isAdmin==true){
                                var allData = response.html;
                                var content = $(allData).html();
                                $('#reviews-list').prepend(content);
                                $('#review-form')[0].reset();
                                $('#review-user-image img').attr('src',$('#review-user-image img').data('src'));
                                $('html,body').animate({
                                    scrollTop: $('#reviews-list').offset().top-100
                                });
                            }else{
                                $('.review-form').html(response.html);
                                $('html,body').animate({
                                    scrollTop: $('.review-form').offset().top-100
                                });
                            }
                        } else if (response.status == 'error') {
                            if(response.error_msg){
                              
                            }else{
                                alert(response.message);
                            }
                        }
                    } //data close

                }, //success close
                complete: function(){
                    _btn.html(txt);
                    _btn.removeAttr('disabled');
                    fn.refreshCaptcha();
                },
                error :function( jqXhr ) {
                  
                    //process validation errors here.
                    $errors = jqXhr.responseJSON; //this will get the errors response data.
                    //show them somewhere in the markup
                    $.each( $errors, function( key, value ) {
                        console.log(key+" "+value);
                        var _inp = $(form).find('input[name='+key+']');
                        console.log(_inp);
                        var _errelm = _inp.siblings('label.error');
                        console.log(_errelm);
                        if(_errelm.length == 0){
                            console.log(';here');
                            var div = document.createElement('label');
                            div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
                            div.innerHTML = value;
                            _inp.after(div);
                        }else{
                            console.log('there is an elm');
                            _errelm.html(value);
                            _errelm.show();
                        }
                    });
                }
            }); //ajax close
        },
        tellFriend:function(form) {
            var _btn = $('#modal-tell-friend .btn-send');
            console.log(_btn.length);
            _btn.html('Sending...');
            _btn.attr('disabled','disabled');
            $.ajax({
                url: $(form).attr('action'),
                data: $(form).serialize(),
                type: 'POST',
                success: function(response) {
                   
                    var icon = 'warning';
                    var type = 'danger';
                    if (response.status == 'success') {
                        icon = 'check';
                        type = 'success';
                        $(form)[0].reset();
                        var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
                        $('#modal-tell-friend .modal-body').html(content);
                        _btn.hide();
                    }
                },complete:function(){
                    _btn.html('Send');
                    _btn.removeAttr('disabled')
                }
            });
        },
        submitSubscription:function(form) {
            $('#subscription_btn').html('<img src="'+$(form).data('loader')+'"/>');
            $('#subscription_btn').attr('disabled','disabled');
            var ct = $('#subscription_btn').data('content');
            $.ajax({
                data: $(form).serialize(),
                url: $(form).attr('action'),
                type: 'post',
                dataType:'JSON',
                success: function(response) {
                    if (response) {
                        //var response = $.parseJSON(data);
                        if (response.status == 'success') {
                            if(response.redirect_url){
                                window.location.href = response.redirect_url;
                            }
                            $(form)[0].reset();
                            $(form).find('.is-success').each(function(){
                                $(this).removeClass('is-success');
                            });
                           
                        } else if (response.status == 'error') {
                            if(response.error_msg){
                                var elm = $(form).find('input[name=email]');
                                elm.removeClass('is-success').addClass('has-error');
                                elm.attr('data-original-title',"This email has already been subscribed!");
                                elm.tooltip();
                                elm.focus();
                            }else{
                                alert(response.message);
                            }
                        }
                    } //data close

                }, //success close
                complete: function(){
                    $('#subscription_btn').html(ct);
                    $('#subscription_btn').removeAttr('disabled');
                }
            }); //ajax close
        },
        validation:function(){

            jQuery.validator.addMethod("multiemail", function(value, element) {
                if (this.optional(element)) // return true on optional element 
                    return true;
                var emails = value.split(/[;,]+/); // split element by , and ;
                valid = true;
                for (var i in emails) {
                    value = emails[i];
                    valid = valid && jQuery.validator.methods.email.call(this, $.trim(value), element);
                }
                return valid;
            }, jQuery.validator.messages.email);
            //SUBSCRIBE
            $('#subscribe-us').validate({
                rules: {
                    email: { required: true,email:true},
                },
                messages: {
                    email: { required: "Please enter your email address.", },
                },
                errorPlacement: function (error, element) {
                   /* $(element).attr('data-original-title',$(error).text());
                    $(element).tooltip();*/
                    $(element).parents('form').append(error);
                },
                /*success: function (label, element) {
                    $(element).attr('data-original-title','');
                    $(element).tooltip();
                },*/
                highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                },
                submitHandler: function(form) {
                    fn.submitSubscription(form);    
                }
            });

            //TELL A FRIEND FORM
            $('#tell-friend-form').validate({
                ignore: [],
                rules: {
                    name: { required: true },
                    email: { required: true, email: true },
                    friend_email: { required: true,multiemail: true },
                    subject: { required: true },
                    message: { required: true },
                    //'g-recaptcha-response':{required:true}
                },
                messages: {
                    name: { required: 'Please enter your full name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    friend_email: { multiemail: 'Emails should be valid.' },
                    subject: { required: 'Please enter the subject of the email' },
                    message: { required: 'Please enter the message of the email' },
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                //errorElement: 'div',
                //errorLabelContainer: '.contact-errors',
                highlight: function(element) {
                    //$('.contact-errors').addClass('alert alert-danger alert-effect in');
                    //$('.contact-errors a.close').show('');
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    fn.tellFriend(form);
                    //form.submit();
                }
            });

            //CONTACT FORM
            $('#contact-form').validate({
                ignore: [],
                rules: {
                    first_name: { required: true },
                    last_name: { required: true },
                    email: { required: true, email: true },
                    phone: { minlength: 7 },
                    subject: { required: true },
                    message: { required: true },
                    'g-recaptcha-response': { required: true }
                },
                messages: {
                    first_name: { required: 'Please enter your first name.' },
                    last_name: { required: 'Please enter your last name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    phone: { minlength: 'Phone number must be minimum of 7 digits.' },
                    /*country: {required: 'Please enter your country.'},*/
                    subject: { required: 'Please enter the subject of the email' },
                    message: { required: 'Please enter the message of the email' },
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    //$(form).find('button[type=submit]').attr('disabled','disabled');
                    form.submit();
                }
            });

            //PACKAGE ENQUIRY
            $('#enquiry-form').validate({
                ignore: [],
                rules: {
                    name: { required: true},
                    email: { required: true, email: true },
                    phone: { minlength: 7 },
                    message: { required: true },
                    captcha_text: { required: true }
                },
                messages: {
                    name: { required: 'Please enter your full name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    phone: { minlength: 'Phone number must be minimum of 7 digits.' },
                    message: { required: 'Please enter the message of the enquiry.' },
                    captcha_text: { required: 'Captcha is required' }
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    fn.submitEnquiry(form);
                }
            });

            //BOOKING FORM
            $('#booking-form').validate({
                ignore: [],
                rules: {
                    'package_id': { required: true },
                    'start_date': { required: true },
                    'end_date': { required: true },
                    'pax': { required: true,number:true},  
                    'first_name': { required: true,maxlength:200 },
                    'last_name': { required: true,maxlength:200 },
                    'email': { required: true, email: true },
                    'address': { required: true },
                    'city': { required: true },
                    'country': { required: true },
                    'emergency_name': { required: true },
                    'emergency_phone': { required: true},
                    'g-recaptcha-response': { required: true }
                },
                messages: {
                    'package_id': {required: 'Please select a package.'},
                    'start_date': {required: 'Please select the start date of your trip.'},
                    'end_date': {required: 'Please select the end date of your trip.'},
                    'pax': {required: 'Please select the number of passengers.'},
                    'first_name': {required: 'Please enter your first name.'},
                    'last_name': {required: 'Please enter your last name.'},
                    'email': {required: 'Please enter your email address.',email:'Please enter valid email address.'},
                    'address': {required: 'Please enter your address.'},
                    'city': {required: 'Please enter your city.'},
                    'country': {required: 'Please select your country.'},
                    'emergency_name': {required: 'Please enter the emergency contact name.'},
                    'emergency_phone': {required: 'Please enter the emergency contact number.'},
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                /*errorElement: 'div',
                errorLabelContainer: '.booking-errors',*/
                errorPlacement: function (error, element) {
                    if($(element).attr('id')=='g-recaptcha-response'){
                        $(element).parents('.form-group').append(error);
                    }else{
                        error.insertAfter(element);
                    }
                },
                highlight: function(element) {
                    /*$('.booking-errors').addClass('alert alert-danger alert-effect in');
                    $('.booking-errors a.close').show('');*/
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    $(form).find('button[type=submit]').attr('disabled','disabled');
                    form.submit();
                }
            });

            //CUSTOMIZE TRIP
            $('#customize-form').validate({
                ignore: [],
                rules: {
                    first_name: { required: true },
                    last_name: { required: true },
                    email: { required: true, email: true },
                    phone: { minlength: 7 },
                    trip_name: { required: true },
                    trip_duration: { required: true },
                    trip_price: { required: true },
                    'g-recaptcha-response': { required: true }
                },
                messages: {
                    first_name: { required: 'Please enter your first name.' },
                    last_name: { required: 'Please enter your last name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    phone: { minlength: 'Phone number must be minimum of 7 digits.' },
                    trip_name: { required: 'Please enter the trip name' },
                    trip_duration: { required: 'Please select the trip duration' },
                    trip_price: { required: 'Please select the trip price range' },
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    $(form).find('button[type=submit]').attr('disabled','disabled');
                    form.submit();
                }
            });

            //REVIEW FORM
            $('#review-form').validate({
                rules: { 'author': { required: true },
                    'email': { required: true, email: true },
                    'title': { required: true, },
                    'content': { required: true, minlength: 10, },
                },
                messages: { 
                    'author': { required: 'Please enter your Full Name.' },
                    'email': { required: 'Please enter your Email Address.' },
                    'title': { required: 'Please enter Review Title.' },
                    'content': { required: 'Please enter the Review.' },
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    fn.saveReview(form);
                }
            });

            //Get A Call FORM
            $('#get-call-form').validate({
                rules: { 
                    name: { required: true },
                    phone: { required: true},
                    captcha_text: { required: true }
                },
                messages: { 
                    name: { required: 'Please enter your full name.' },
                    phone: { minlength: 'Phone number must be minimum of 7 digits.' },
                    captcha_text: { required: 'Captcha is required' }
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    fn.saveCallRequest(form);
                }
            });

            //HOMESTAY ENQUIRY
            $('#homestay-enquiry-form').validate({
                ignore: [],
                rules: {
                    name: { required: true},
                    email: { required: true, email: true },
                    phone: { minlength: 7 },
                    message: { required: true },
                    "g-recaptcha-response": { required: true }
                },
                messages: {
                    name: { required: 'Please enter your full name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    phone: { minlength: 'Phone number must be minimum of 7 digits.' },
                    message: { required: 'Please enter the message of the enquiry.' },
                    "g-recaptcha-response": { required: 'Captcha is required' }
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    fn.submitHomeStayEnquiry(form);
                }
            });

        },
        loadPriceRange:function(){
            var minRange = parseInt($("#slider-range").data('minrange'));
            var maxRange = parseInt($("#slider-range").data('maxrange'));
            var minVal = parseInt($("#slider-range").data('min'));
            var maxVal= parseInt($("#slider-range").data('max'));
             $( "#slider-range" ).slider({
                  range: true,
                  min: minRange,
                  max: maxRange,
                  values: [ minVal, maxVal ],
                  slide: function( event, ui ) {
                    $( "#amount" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
                  }
                });
                $( "#amount" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) +
                  " - $" + $( "#slider-range" ).slider( "values", 1 ) );

        },

        nicescroll:function(){
            var container=$(".recently-viewed-container ul");
            $(container).niceScroll();

            $(".navbar .dropdown-toggle").on("click",function(){
                $(".navbar-nav .dropdown.open .dropdown-menu .main-nav__sub--region .lists ul").niceScroll();
            });
        },
          slick:function(){         
            var x=6;
             if($(window).width() < 660){
               x=4;
              }
            $('.slider-for').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                arrows: false,
                fade: true,
                asNavFor: '.slider-nav'
            });

            $('.slider-nav').slick({
                slidesToShow: x,
                slidesToScroll: 1,
                speed: 500,
                asNavFor: '.slider-for',
                dots: false,
                centerMode: true,
                focusOnSelect: true,
                slide: 'div'
            });

           
        }


	}
	// fn closer

	$(document).ready(function(){
		fn.Launch();
        if($('.captcha-img').length>0){
            fn.refreshCaptcha();
        }
		if ($('#find_destination').length > 0) {
            fn.findTrips();fn.findActivity();
        }
        if ($('#contact-form').length > 0) {
            fn.loadCountries('#country_name',$('#contact-form').data('jsonurl'));
        }
        if($('#load-more-blog').length >0 ){
            fn.loadBlogs();
        }
        if($('#load-more-testimonials').length >0 ){
            fn.loadTestimonials();
        }
        if($('#select-member').length > 0){
            fn.selectMember();
        }
        if($('#load-more-reviews').length >0 ){
            fn.loadReviews();
        }
	});
})